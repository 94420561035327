import React from "react";
import Slider from 'react-slick';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {graphql, useStaticQuery} from "gatsby";
import SliderItemGraphql from "./SliderItemGraphql";

export default () => {
    const data = useStaticQuery(graphql`
    query {
  page:   allParagraphSliderComponent(filter: {parent_id: {glob: "23"}}) {
    edges {
      node {
        relationships {
          item: field_item_component_slider {
            field_ath_component_slide_item
            field_pos_component_slide_item
            field_text_comp_slide_item {
              value
            }
            field_title_component_slide_item
          }
        }
      }
    }
  }
  }`);

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false,
            }
          },
        ]
    };

    const items = data.page.edges[0].node.relationships.item.map((data, i) => <SliderItemGraphql key={i} data={data} />);

    return (
        <section className="slider">
            <div className="container">
                <div className="row">
                    <Slider {...settings}>
                        {items}
                    </Slider>
                </div>
            </div>
        </section>
    );
}