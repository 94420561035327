import React from "react"
import {useStaticQuery, graphql, Link} from "gatsby"

export default ({ menuItems, langCode }) => {
    const prefix = langCode === 'en' ? '' : `/${langCode}`;
    const showMenu = (e, data) => {

        let clicedEl = e.currentTarget.id.replace( "-btn", '-block');
        let element = document.getElementById(clicedEl);

       // console.log(element)
        let elementBefor = document.querySelector(".dropdown-content-mobile:not(.d-none)");
        if (elementBefor) {
            elementBefor.classList.add("d-none");
            document.querySelector(".active").classList.remove("active");
        }

        document.getElementById(e.currentTarget.id).classList.add("active");
        if (element.classList.contains('menu-active')) {
            //console.log("te");
            element.classList.remove("menu-active");
            document.getElementById(e.currentTarget.id).classList.remove('active');
            element.classList.add("d-none");
        } else {
            element.classList.remove("d-none");
            element.classList.add("menu-active");
            //console.log("te22");
        }

    }

    const items = Object.values(menuItems).map((item, i) => {
      return (
          <div className="dropdown-mobile">
            <button className="dropbtn-mobile" id={item[langCode].title.toLowerCase() + "-btn"} onClick={showMenu} key={i}>{item[langCode].title}</button>
            <div className="dropdown-content-mobile d-none" id={item[langCode].title.toLowerCase() + "-block"}>
                <Link className="link-mobile-first-lvl" key={i} to={`${prefix}${item[langCode].url}`}>{item[langCode].title}</Link>
                {Object.values(item['__children']).map((item2, i)=> {
                    return <Link key={i} to={`${prefix}${item2[langCode].url}`}>{item2[langCode].title}</Link>;
                })}
            </div>
          </div>
      )
    });

    return  items
}
