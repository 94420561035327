import React, {useState} from "react"

import { t } from '../utils/i18n';

//import logoDefault from "../images/vpg_obw.svg";
import logoDefault from "../images/OBW_Horizontal_Blue_Black.svg";
import logoSI from "../images/SI-On-Board-Weighing-RGB-100px.png";
import linkedin from "../images/linkedin.svg";
import facebook from "../images/facebook-logo.svg";
import twitter from "../images/twitter.svg";
import youtube from "../images/youtube.svg";
import { useForm } from "react-hook-form";
import {useStaticQuery, graphql, Link} from "gatsby";

// const { GATSBY_API_ENV = 'default' } = process.env;

export default ({ siteTitle, langCode = 'en' }) => {
  const { register, handleSubmit, reset, errors, setError } = useForm();

  const [input, setInput] = useState(null);

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            envName
          }
        }
      }
    `
  )

  const envName = site.siteMetadata?.envName;

  const onSubmit = data => {

    var getUrl = window.location;
    var baseUrl = getUrl .protocol + "//" + getUrl.host + "/";
    window.location.replace(`${baseUrl}search-page?keys=${input}`);

  };


  return (
  <header>
    <div className="container">
      <div className={"row" + (envName === 'si' ? ' si' : '')}>
        <div className="col-6">
          <Link to="/">
            {envName === 'default' &&
              <img src={logoDefault} className="heade-logo" alt=""/>
            }
            {envName === 'si' &&
              <img src={logoSI} className="heade-logo" alt=""/>
            }
          </Link>
        </div>
        <div className="col-md-6 d-flex justify-content-end">
          <div className="wrapper">
            <form onSubmit={handleSubmit(onSubmit)}>
              <input type="text" name="search" id="search" placeholder={t("Enter your keywords", langCode)} onInput={e => setInput(e.target.value)}/>
              <button type="submit">{t("Search", langCode)}</button>
            </form>
            <div className="social_links">
              <a href="https://www.youtube.com/channel/UC24fGJ56IT-fysQyjCzW_4w">
                <img src={youtube} alt="" />
              </a>
              <a href="https://twitter.com/VPGOnboard">
                <img src={twitter} alt="" />
              </a>
              <a href="https://www.linkedin.com/showcase/vpg-onboard-weighing">
                <img src={linkedin} alt="" />
              </a>
              {/*<a href="#">*/}
              {/*  <img src={facebook} alt="" />*/}
              {/*</a>*/}


            </div>
          </div>

        </div>
      </div>
    </div>
  </header>
  )
};
