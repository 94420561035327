import React from "react"
import {useStaticQuery, graphql, Link} from "gatsby"

export default ({ menuItems, langCode }) => {
  const prefix = langCode === 'en' ? '' : `/${langCode}`;
    const items = Object.values(menuItems).map((item, i) => {
      return (
          <div className="dropdown">
            <Link className="dropbtn" key={i} to={`${prefix}${item[langCode].url}`}>{item[langCode].title}</Link>
            <div className="dropdown-content">
                {Object.values(item['__children']).map((item2, i)=> {
                  return <Link key={i} to={`${prefix}${item2[langCode].url}`}>{item2[langCode].title}</Link>;
                })}
            </div>
          </div>
      )
    });
    return  items
}
