import React, {useState} from "react";
import ReactModal from 'react-modal'
import DrupalContactWidgetForm from "./DrupalContactWidgetForm";
import cancelImg from "../images/cancel.svg"

export default () => {
    const [modalIsOpen,setIsOpen] = useState(false);

    const customStyles = {
        content : {
            top                   : '50%',
            left                  : '50%',
            right                 : 'auto',
            bottom                : 'auto',
            marginRight           : '-50%',
            transform             : 'translate(-50%, -50%)',
            padding     : '10px',
            background: '#FFFFFF'
        }
    };

    function openModal() {
        setIsOpen(true);
    }


    function closeModal(){
        setIsOpen(false);
    }

    return (
        <div className={"contact-block"} id="main">

            <button className={"contact-block"} onClick={openModal}>Contact us</button>


            {/*<a href="#" className={"contact-block"} onClick={openModal}>Contact us</a>*/}
            <ReactModal
                isOpen={modalIsOpen}
                onRequestClose={modalIsOpen}
                style={customStyles}
            >
                <section className="contact-form-widget">

                    <button className="contact-form-widget-close" onClick={closeModal}><img src={cancelImg} alt=""/></button>

                    <DrupalContactWidgetForm />
                </section>

            </ReactModal>
        </div>
    );
};
