import React from "react"
import {Link} from "gatsby"

export default ({ menuItems, langCode }) => {
  const prefix = langCode === 'en' ? '' : `/${langCode}`;
    const items = Object.values(menuItems).map((item, i) => {
      return (
              <li key={i}><Link className="dropbtn" to={`${prefix}${item[langCode].url}`}>{item[langCode].title}</Link></li>
      )
    });

  return  <ul>{items}</ul>
}
