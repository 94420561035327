import React, {useState, useEffect} from "react";

import linkedin from "../images/linkedin.svg";
import facebook from "../images/facebook-logo.svg";
import twitter from "../images/twitter.svg";

import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
} from "react-share";

export default () => {
    const [url, setUrl] = useState('');

    useEffect(() => setUrl(window.location.href), [setUrl]);

    return (

        <section className="container social">
            <div className="row">
                <div className="col-md-4">
                    <h5>Share this page</h5>
                    {/*<a href="#"><img src={linkedin} alt="" /></a>*/}
                    {/*<a href="#"><img src={facebook} alt="" /></a>*/}
                    {/*<a href="#"><img src={twitter} alt="" /></a>*/}
                    {/*<a href="#"><img src={youtube} alt="" /></a>*/}

                    <FacebookShareButton url={url} >
                        <img src={facebook} alt="" />
                    </FacebookShareButton>
                    <LinkedinShareButton url={url} >
                        <img src={linkedin} alt="" />
                    </LinkedinShareButton>
                    <TwitterShareButton url={url} >
                        <img src={twitter} alt="" />
                    </TwitterShareButton>
                </div>
            </div>
        </section>

);
}
