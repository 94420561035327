import React from "react";


export default ({ data }) => {
    // console.log("32333")
    //  console.log(data);
    // const {
    //     field_title_component_slide_item = [],
    //     field_text_component_slide_item = [],
    //     field_pos_component_slide_item = [],
    //     field_ath_component_slide_item = [],
    // } = data || {};

    const title = data.field_title_component_slide_item;
    const text = data.field_text_comp_slide_item;
    const author = data.field_ath_component_slide_item;
    const position = data.field_pos_component_slide_item;

    return (
        <div className="col-md-11">
            <div className="slider-left">
                {title !== undefined &&
                    <h2>{title}</h2>
                }
                {author !== undefined &&
                    <span className="slider-author">{author}</span>
                }
                {position !== undefined &&
                    <span className="slider-position-author">{position}</span>
                }
            </div>
            <div className="slider-right">
                {text &&
                    <div dangerouslySetInnerHTML={{
                        __html: text.value
                    }}></div>
                }
            </div>
        </div>
    )
}
