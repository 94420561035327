import React, {useState} from "react";
import Menus from "./DrupalMenu"
import MenusMobile from "./DrupalMenuMobile"



export default ({ menus, langCode }) => {
    const [isActive, setActive] = useState(false);

    const { main:menuItems = {} } = menus || {};

    function showMenu() {
        setActive(!isActive);
    }
    return (
        [<section className="mein-menu">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <Menus menuItems={menuItems} langCode={langCode} />
                    </div>
                </div>
            </div>
        </section>,
            <section className="mein-menu-mobile">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <button id="main-menu-mobile" onClick={showMenu}>Main Menu</button>
                            <div className={isActive ? 'main-menu-mobile-elements d-block' : 'main-menu-mobile-elements d-none'}>
                                <MenusMobile menuItems={menuItems} langCode={langCode} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>]
    );
}
