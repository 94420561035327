const strings = {
  'en': {},

  'de': {
    'Enter your keywords': 'Geben Sie Ihr Passwort in',
    'Search': 'Suche',
    'INQUIRY FORM': 'Anfrage Formular',
    'Name': 'Name',
    'Email': 'Email',
    'Country': 'Land',
    'Phone': 'Telefon',
    'Company': 'Firma',
    'Message': 'Nachricht',
    'Submit': 'Bestätigen',
  },

  'fr': {
    'Enter your keywords': 'Entrez vos mots-clés',
    'Search': 'Recherche',
    'INQUIRY FORM': 'FORMULAIRE DE DEMANDE',
    'Name': 'Nom',
    'Email': 'Email',
    'Country': 'Pays',
    'Phone': 'Téléphone',
    'Company': 'Enterprise',
    'Message': 'Message',
    'Submit': 'Soumettre',
  },

  'es': {
    'Enter your keywords': 'Introduzca sus palabras clave',
    'Search': 'Buscar',
    'INQUIRY FORM': 'FORMULARIO DE CONSULTA',
    'Name': 'Nombre',
    'Email': 'Correo electrónico',
    'Country': 'País',
    'Phone': 'Teléfono',
    'Company': 'Compañía',
    'Message': 'Mensaje',
    'Submit': 'Entregar',
  },
};

export const t = (givenString, lang) =>
  !!(strings[lang] && strings[lang][givenString]) ? strings[lang][givenString] : givenString;
