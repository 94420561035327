import React, {useState} from "react";
import axios from 'axios';
import { Button, Alert } from "reactstrap";
import { useForm } from "react-hook-form";
import {graphql, useStaticQuery} from "gatsby";
const { GATSBY_API_URL = 'http://localhost' } = process.env;
// const { GATSBY_API_ENV = 'default' } = process.env;

export default () => {
    const [ msg, setMsg ] = useState(null);
    const { register, handleSubmit, reset, errors, setError } = useForm();

    const { site } = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            envName
          }
        }
      }
    `
    )

    const envName = site.siteMetadata?.envName;

    const onSubmit = data => {
        const webform_id = "inquiry_webform";
        setMsg(null);
        axios.post(`${GATSBY_API_URL}/webform_rest/submit`, { ...data, webform_id })
            .then(() => {
                setMsg("Successfully sent.");
                reset({});
            })
            .catch(({ response: { data:e } }) => {
                reset(data);
                if (e && e.message && e.error) {
                    for (let key in e.error) {
                        setError(key, { type: 'validate', message: e.error[key] });
                    }
                }
                console.log(e);
            });
    };
    const fields = [
        {
            key: "name",
            label: "Name",
            required: true,
            type: "text",
        },
        {
            key: "env",
            label: "env",
            required: true,
            type: "text",
        },
        {
            key: "email",
            label: "Email",
            required: true,
            type: "text",
        },
        {
            key: "country",
            label: "Country",
            required: true,
            type: "text",
        },
        {
            key: "phone",
            label: "Phone",
            required: false,
            type: "text",
        },
        {
            key: "company",
            label: "Company",
            required: false,
            type: "text",
        },
        {
            key: "message",
            label: "Message",
            required: true,
            type: "textarea",
        },
    ];
    const RawHTML = ({children, className = ""}) =>
        <div
            className={className}
            dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, '<br />')}}
        />;
    const FormComponent = (props) => {
        switch (props.type) {
            case "textarea":
                // return <textarea { ...props } reg="" ref={props.reg} />
                return (
                    // <div className={props.nameclass}>
                        [<label>{props.title}</label>,
                        <textarea { ...props } reg="" ref={props.reg} />]
                    // </div>
                );
            default:
                // return <input { ...props } reg="" ref={props.reg} />
                //console.log(props);
                return (
                   // <div className={props.nameclass}>
                        [<label>{props.title}</label>,
                        <input { ...props } reg="" ref={props.reg} value={props.val} />]
                    //</div>
                );
        }
    };
    return (
            <div className="container">
                <div className="row">

                        {msg && <Alert color="success">{msg}</Alert>}
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {
                                fields.map(({ key, label, required, type}) => (
                                    <div key={key} className= {required ? "contact-el required" : "contact-el"} id={key === 'env' ? 'env': null}>
                                        <FormComponent
                                            htmlFor={key}
                                            id={key}
                                            title={label}
                                            required={required}
                                            type={type}
                                            name={key}
                                            reg={register({required})}
                                            val={key === 'env' ? envName : null}
                                        />
                                        {
                                            errors[key] && <span
                                                className="text-danger small"><RawHTML>{errors[key].message || 'This field is required'}</RawHTML></span>
                                        }
                                    </div>
                                ))
                            }
                            <div className="contact-el submit">
                                <input className="submit-btn" type="submit" value="Submit"/>
                            </div>
                        </form>

                </div>
            </div>
    );
};
